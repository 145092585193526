import { Controller } from 'stimulus'
import Rails from "@rails/ujs";
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = [ "ancestor", "parent", "child" ]

  connect() {
    if(this.ancestorTarget.value !== "") {
      this.element.querySelector("#studentSchoolTrainings").classList.remove("d-none");
    }
    if(this.parentTarget.value !== "") {
      this.element.querySelector("#studentTrainingLevels").classList.remove("d-none");
    }
  }

  updateOptions(data, dropdown) {
    var $select = $(dropdown);
    $select.empty().append("<option value=''>Sélectionne</option>").select2({
      data: data
    }).trigger("change");
  }

  updateDropdown(url, recordId, dropdown) {
    Rails.ajax({
      type: "get",
      url: url,
      data: `id=${recordId}`,
      success: (data) => {
        this.updateOptions(data, dropdown);
      }
    })
  }

  saveAncestorAndDisplayParent(e) {
    if(e.currentTarget.value === "") {
      this.updateOptions([], this.parentTarget, "-");
      this.element.querySelector("#studentSchoolTrainings").classList.add("d-none");
      this.element.querySelector("#studentTrainingLevels").classList.add("d-none");
      this.updateOptions([], this.childTarget, "-");
    } else {
      this.element.querySelector("#studentSchoolTrainings").classList.remove("d-none");
      this.updateDropdown(e.currentTarget.dataset.url, e.currentTarget.value, this.parentTarget);
    }
  }

  saveParentAndDisplayChild(e) {
    if(e.currentTarget.value === "") {
      this.updateOptions([], this.childTarget, "-");
        this.element.querySelector("#studentTrainingLevels").classList.add("d-none");
    } else {
      this.element.querySelector("#studentTrainingLevels").classList.remove("d-none");
      this.updateDropdown(e.currentTarget.dataset.url, e.currentTarget.value, this.childTarget);
    }
  }
}
