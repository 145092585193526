import { Controller } from 'stimulus'
import mapboxgl from 'mapbox-gl'

let currentInstance = null

export default class extends Controller {
  static targets = ['map', 'wrapper', 'marker']
  static values = { token: String }

  connect() {
    this.map = null
    this.markers = null
    this.initMapbox()
    this.fitMapToMarkers()
  }

  initMapbox() {
    mapboxgl.accessToken = this.wrapperTarget.dataset.mapboxApiKey
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v10'
    })
    currentInstance = this
    if(this.wrapperTarget.dataset.markers) {
      this.markers = JSON.parse(this.wrapperTarget.dataset.markers);
      this.markers.forEach((marker) => {
        this.addMarker(marker)
      })
    }
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
  }

  addMarker(marker) {
    new mapboxgl.Marker().setLngLat([marker.lng, marker.lat])
    const popup = new mapboxgl.Popup({ closeButton: false }).setHTML(marker.infoWindow);
    const element = document.createElement('div');
    element.className = 'marker';
    element.style.backgroundImage = `url('${marker.image_url}')`;
    element.style.backgroundSize = 'cover';
    element.style.width = '20px';
    element.style.height = '20px';
    element.setAttribute('data-mapbox-target', 'marker');
    new mapboxgl.Marker(element)
      .setLngLat([ marker.lng, marker.lat ])
      .setPopup(popup)
      .addTo(this.map);
    // TODO find a way to manage both hover & click events for markers
    // element.addEventListener('mouseenter', () => marker.togglePopup()); // This pollutes click event (which is need for touch devices)
    // element.addEventListener('mouseleave', () => marker.togglePopup()); // This pollutes click event (which is need for touch devices)
  }

  fitMapToMarkers() {
    if(this.markers) {
      const bounds = new mapboxgl.LngLatBounds();
      this.markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
      this.map.fitBounds(bounds, { padding: 30, duration: 0 });
    }
  }

  updateMarkers() {
    this.markerTargets.forEach((marker) => {
      marker.remove();
    })
    this.markers = JSON.parse(this.wrapperTarget.dataset.markers)
    this.markers.forEach((marker) => {
      this.addMarker(marker);
    })
    this.fitMapToMarkers()
  }

  tokenValueChanged() {
    if (this.markers) {
      this.updateMarkers();
    }
  }
}

export {
  currentInstance // This way we can access map object from another stimulus controller
}
