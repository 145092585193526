import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "template", "select", "hiddenFieldsContainer", "hiddenField" ]
  static values = { "possibleOptions": Array, "savedOptions": Array }

  connect() {
    this.childIndex = this.element.dataset.childIndex;
    // PROGRAMATICALLY SELECT OPTIONS UPON ARRIVAL ON PAGE (based on what's on DB)
    this.applySelection();
    // CREATE LATEST SELECTION VARIABLE (which purpose is to enable unselection detection)
    this.latestSelection = this.getSelectedValues(this.selectTarget);
  }

  applySelection() {
    $(".nested-form-hidden .select2-multiple").val(this.savedOptionsValue);
  }

  updateHiddenFields(e) {
    // GET SELECTED VALUES
    const selectedValues = this.getSelectedValues(e.currentTarget);
    // ADD OR UPDATE HIDDEN FIELDS FOR SELECTED OPTIONS
    this.afterSelectUpdate(selectedValues);
    // MARK HIDDEN FIELDS FOR DELETION IF NEEDED
    this.afterUnselectUpdate(selectedValues);
    // UPDATE LATEST SELECTION VARIABLE (which purpose is to enable unselection detection)
    this.latestSelection = selectedValues;
  }

  getSelectedValues(select) {
    let values = [];
    const options = select && select.options;
    let option;

    for (let i=0, optionsNb=options.length; i < optionsNb; i++) {
      option = options[i];
      if (option.selected) {
        values.push(option.value || option.text);
      }
    }
    return values;
  }

  afterSelectUpdate(selectedValues) {
    selectedValues.forEach((value) => {
      const hiddenField = this.hiddenFieldTargets.find(el => el.value === value);
      if(hiddenField) {
        // IF A HIDDEN FIELD EXISTS WITH THIS VALUE, DO NOT ADD ANOTHER ONE (but ensure it is not marked for deletion)
        hiddenField.parentElement.nextElementSibling.value = 0;
      } else if(value) {
        // IF NO HIDDEN FIELD HAS THIS VALUE (and value is not ""), INSERT ONE USING TEMPLATE AND ASSIGN VALUE
        let content = this.templateTarget.innerHTML.replace(new RegExp(this.childIndex,"g"), new Date().getTime());
        this.hiddenFieldsContainerTarget.insertAdjacentHTML("beforeend", content);
        this.hiddenFieldsContainerTarget.lastElementChild.querySelector("select").value = value;
      }
    })
  }

  afterUnselectUpdate(selectedValues) {
    // TARGET FIELDS FOR UNSELECTED VALUES AND MARK THEM FOR DELETION
    const unselectedValues = this.latestSelection.filter(el => !selectedValues.some(value => value === el));
    unselectedValues.forEach((unselectedValue) => {
      const field = this.findHiddenField(unselectedValue);
      if(field){
        this.markForDeletion(field);
      }
    })
  }

  markForDeletion(hiddenField) {
    const fieldTarget = hiddenField.closest(".hidden-field");
    fieldTarget.querySelector(".hiddenDestroy").value = 1;
  }

  findHiddenField(value) {
    return this.hiddenFieldTargets.find(el => el.value === value);
  }
}
