import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "keywordInput", "searchInput", "keywordList"]

  updateKeyword() {
    const keyword = this.keywordInputTarget.value
    if (null != keyword) {
      const option = this.searchInputTarget.querySelector(`option[value="${keyword}"]`)
      option.selected = true;
      this.keywordListTarget.insertAdjacentHTML(
        "beforeend", `<div class="d-flex font-size-14px text-purple ms-3 keyword">${option.textContent}<span class="ms-2 cursor-pointer font-size-16px text-dark" data-action="click->input#removeKeyword" data-text="${keyword}">x</span></div>`
      )
      this.keywordInputTarget.value = null;

    }
  }

  removeKeyword() {
    const keyword = event.currentTarget.dataset.text;
    const option = this.searchInputTarget.querySelector(`option[value="${keyword}"]`)
    option.selected = false;
    const elementToRemove = event.currentTarget.parentNode;
    elementToRemove.parentNode.removeChild(elementToRemove);
  }
}
