import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "navbar", "cciLogo" ]

  toggleNavbarFixed(e) {
    let windowY = window.scrollY;
    const navbar = this.navbarTarget;
    if (windowY > navbar.offsetHeight) {
      this.cciLogoTargets.forEach((cciLogo)=>{
        cciLogo.classList.add("d-none")
      })
      navbar.classList.remove('navbar-home');
      navbar.classList.add("navbar-z-index");
    } else {
      this.cciLogoTargets.forEach((cciLogo)=>{
        cciLogo.classList.remove("d-none")
      })
      navbar.classList.add('navbar-home');
      navbar.classList.remove("navbar-z-index");
    }
  }
}
