import mapboxgl from 'mapbox-gl';
// import { perigordVertPolygonCoordinates, nord24Sud87PolygonCoordinates, brianceSudHauteViennePolygonCoordinates, perigordVertFeatures, nord24Sud87Features, brianceSudHauteVienneFeatures } from './upcoming_areas_polygons'

const manageAreasMap = () => {
  const mapElement = document.querySelector('#homeMap');
  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'homeMap',
      style: 'mapbox://styles/mapbox/streets-v11',
      attributionControl: false
    });
    let hoveredEpci = {};

    const fitMapToPolygons = (map, polygons) => {
      const coordinates_array = JSON.parse(polygons).flat();
      const bounds = new mapboxgl.LngLatBounds();
      coordinates_array.forEach(coordinates => bounds.extend([ coordinates[0], coordinates[1] ]));
      map.fitBounds(bounds, { padding: 80, maxZoom: 7.5, minZoom: 7, duration: 0 });
    };
    fitMapToPolygons(map, mapElement.dataset.coordinates);

    const manageEvent = (map, source)=> {
      map.on('click', source, function(element) {
        if (element.features[0].properties.site_url !== '') {
          window.location.href = element.features[0].properties.site_url;
        }
      });

      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      map.on('mouseenter', source, function(e) {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', source, function() {
        map.getCanvas().style.cursor = '';
      });

      map.on('mousemove', source, function(e) {
        if (e.features.length > 0) {
          if (hoveredEpci[source]) {
            map.setFeatureState(
              { source: source, id: hoveredEpci[source] },
              { hover: false }
            );
          }
          hoveredEpci[source] = e.features[0].id;
          map.setFeatureState(
            { source: source, id: hoveredEpci[source] },
            { hover: true }
          );
          var coordinates = e.lngLat;
          var description = e.features[0].properties.description;
          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        }
      });

      map.on('mouseleave', source, function() {
        if (hoveredEpci[source]) {
          map.setFeatureState(
            { source: source, id: hoveredEpci[source] },
            { hover: false }
          );
          popup.remove();
        }
        hoveredEpci[source] = null;
      });
    }

    map.on('load', function() {

      map.addSource('epci-cci', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': JSON.parse(mapElement.dataset.cciFeatures)
        }
      });
      map.addLayer({
        'id': 'epci-cci',
        'type': 'fill',
        'source': 'epci-cci',
        'layout': {},
        'paint': {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(230, 177, 38, 0.7)',
            'rgba(230, 177, 38, 0.2)'
          ]
        }
      });
      map.addLayer({
        'id': 'epci-cci-borders',
        'type': 'line',
        'source': 'epci-cci',
        'layout': {},
        'paint': {
          'line-color': '#ffffff',
          'line-width': 2
        }
      });

      //BERGERAC

      map.addSource('epci-bergerac', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': JSON.parse(mapElement.dataset.bergeracFeatures)
        }
      });
      map.addLayer({
        'id': 'epci-bergerac',
        'type': 'fill',
        'source': 'epci-bergerac',
        'layout': {},
        'paint': {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(230, 134, 38, 0.7)',
            'rgba(230, 134, 38, 1)'
          ]
        }
      });
      map.addLayer({
        'id': 'epci-bergerac-borders',
        'type': 'line',
        'source': 'epci-bergerac',
        'layout': {},
        'paint': {
          'line-color': '#ffffff',
          'line-width': 2
        }
      });

      //BERGERAC END




      map.addSource('epci', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': JSON.parse(mapElement.dataset.accessFeatures)
        }
      });
      map.addLayer({
        'id': 'epci',
        'type': 'fill',
        'source': 'epci',
        'layout': {},
        'paint': {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(58, 55, 144, 0.7)',
            'rgba(58, 55, 144, 0.4)'
          ]
        }
      });
      map.addLayer({
        'id': 'epci-borders',
        'type': 'line',
        'source': 'epci',
        'layout': {},
        'paint': {
          'line-color': '#ffffff',
          'line-width': 2
        }
      });
    });
    manageEvent(map, "epci-cci")
    manageEvent(map, "epci-bergerac")
    manageEvent(map, "epci")


    map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    map.addControl(new mapboxgl.AttributionControl(), 'top-right');
  }
};

export { manageAreasMap };
