const manageGeneralOfferModalContent = () => {
  const offerModalBtns = document.querySelectorAll(".offerModalBtn");
  offerModalBtns.forEach((modalBtn)=>{
    modalBtn.addEventListener("click", e => {
      const placesNb = document.querySelector(e.currentTarget.dataset.placesNb).value;
      document.querySelector(e.currentTarget.dataset.bsTarget).querySelector(".placesNb").innerText = placesNb;
    })
  })

}

export { manageGeneralOfferModalContent }
