import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { changeConditionMet: Boolean }
  static classes = [ "active", "inactive" ]
  static targets = [ "button" ]

  connect() {
  }

  updateColors() {
    this.buttonTargets.forEach(button => {
      this.switchClasses(button)
    });
  }

  switchClasses(button) {
    button.classList.toggle(this.activeClass);
    button.classList.toggle(this.inactiveClass);
  }

  changeConditionMetValueChanged() {
    this.updateColors();
  }
}
