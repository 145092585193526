import $ from 'jquery';
import 'select2';

const initSelect2 = () => {
  $('select.select2, select.select2-multiple').select2({ width: '100%',dropdownAutoWidth : true });
  $('.select2-referential').select2({ width: '100%',dropdownAutoWidth : true, placeholder: "ex : soins" });
  $('select.select2, select.select2-multiple').on('change:select2 select2:select select2:unselect', function (e) {
    const event = new Event('change', { bubbles: true }); // Fires a native event
    e.currentTarget.dispatchEvent(event);
  });
};
export { initSelect2 };
