import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "preview", "imageInput" ]
  static values = { url: String }

  connect() {
    if(this.urlValue !== "") {
      this.previewTarget.style.backgroundImage = "url(" + this.urlValue + ")";
      this.previewTarget.classList.add("d-block");
      this.previewTarget.classList.remove("d-none");
    }
  }

  readFileAsDataUrl(file){
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  updatePreview(e) {
    let readers = [];
    readers.push(this.readFileAsDataUrl(e.currentTarget.files[0]));
    Promise.all(readers).then((urls) => {
      if(urls[0]) {
        this.previewTarget.style.backgroundImage = "url(" + urls[0] + ")";
        this.previewTarget.classList.add("d-block");
        this.previewTarget.classList.remove("d-none");
      }
    });
  }

  clearInput(e) {
    this.previewTarget.style.backgroundImage = "";
    this.previewTarget.classList.remove("d-block");
    this.previewTarget.classList.add("d-none");
    this.imageInputTarget.value = ""
    if(this.urlValue !== "") {
      this.element.querySelector("#destroyInput").value = true
    }
  }
}
