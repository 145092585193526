require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

var Turbolinks = require("turbolinks")
Turbolinks.start()

// External imports
import "bootstrap";

// Internal imports
import { initCookieBanner } from '../components/cookies'
import { manageGeneralOfferModalContent } from '../components/inputs'
import { manageAreasMap } from '../plugins/areas_map';
import { initAutocomplete } from '../plugins/init_autocomplete';
import { initSelect2 } from '../plugins/init_select2';
import { initFlatpickr } from '../plugins/init_flatpickr';

document.addEventListener('turbolinks:load', () => {
  initCookieBanner();
  manageAreasMap();
  initAutocomplete();
  initSelect2();
  initFlatpickr();
  manageGeneralOfferModalContent();
});

import "controllers"
