import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

const initFlatpickr = () => {
  flatpickr(".flatpickr", {
    altInput: true,
    altFormat: "j F Y",
    dateFormat: "d-m-Y",
    locale: French
  });
}

export { initFlatpickr };
